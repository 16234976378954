@import url('https://rsms.me/inter/inter.css');

* {
    box-sizing: border-box;
}

::selection {
    background-color: rgb(255, 249, 168);
    color: black;
}

html,
body,
#root {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

body {
    overscroll-behavior-y: none;
    background: #f5f5f5;
    font-family: 'Inter var', Arial, Helvetica, Verdana, Tahoma, Calibri, sans-serif;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    color: black;
}

a {
    pointer-events: all;
    color: black;
    text-decoration: none;
}

.noInteract {
    user-select: none !important;
    pointer-events: none !important;
}

/* 
    OVERLAY
 */

.Overlay {
    position: absolute;
    /* z-index: 99999999; */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    /* background: radial-gradient(circle at center, transparent 70%, white 90%); */
}

.corner {
    position: absolute;
    z-index: 99999999;
    font-size: 13px;
    pointer-events: auto;
}

.corner.top-left {
    top: 24px;
    left: 24px;
}

.corner.bottom-left {
    bottom: 24px;
    left: 24px;
}

.corner.top-right {
    top: 18px;
    right: 24px;
    display: flex;
    gap: 16px;
}

.bottom {
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    /* assuming the element should be absolutely positioned */
}

.fadeOut {
    animation: fadeOutEffect 0.5s ease-in forwards;

}

.fadeIn {
    animation: fadeInEffect 0.5s ease-out forwards;

}

.fadeInOut {
    animation: fadeInOutEffect 1s ease-in-out 6;
}

@keyframes fadeInEffect {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeOutEffect {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes fadeInOutEffect {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0.4;
    }
}


/* 
    Image
 */

.ImageTitle {
    position: absolute;
    font-size: 30px;
    z-index: 9999999999;
    transform: translateY(-45px);
    transform-style: preserve-3d;
    opacity: 0;
    user-select: none;
    transition: opacity 0.2s ease;
    pointer-events: none;
    white-space: nowrap;
    overflow: hidden;
    background-color: #f5f5f5;
}

.ImageTitle svg {
    font-size: 28px;
    transform: translate(-4px, 2px);
}

.ImageWrapper {
    transform: scale(0.9);
    transition: transform 0.2s ease;
}

.ImageCropper {
    overflow: hidden;
}

.ImageWrapper .Image {
    cursor: pointer;
    filter: grayscale(100%);
    border: 0px solid white;
    box-sizing: border-box;
    transform: scale(1.1);
    transition: all 0.2s ease;
    user-select: none;
}

.ImageTitle.dark-mode {
    background-color: #111;
}

.ImageWrapper:hover {
    transform: scale(1);
}

.ImageWrapper:hover .Image {
    filter: grayscale(0%);
    transform: scale(1);
}

.ImageWrapper:hover .ImageTitle {
    opacity: 1;
}

.ImageWrapper:active {
    transform: scale(0.9);
}

.dark-mode {
    color: #eee;
}

.ImageWrapper video {
    cursor: pointer;
    filter: grayscale(100%);
    transform: scale(1.1);
    transition: all 0.2s ease;
}

.ImageWrapper.on {
    transform: scale(1);
}

.ImageWrapper.on video {
    filter: grayscale(0%);
    transform: scale(1);
}

.ImageWrapper:hover video {
    filter: grayscale(0%);
    transform: scale(1);
}

.ImageWrapper video.active {
    filter: grayscale(0%);
    transform: scale(1);
}